<!--
 * @Author: chenjx
 * @Date: 2023-08-13 12:18:56
 * @LastEditors: chenjx
 * @LastEditTime: 2023-09-26 17:47:51
 * @Description: file content
 * @FilePath: \lingtong\src\App.vue
-->
<template>
  <div>
    <header-conponent class="hidden-sm-and-up" :header="header" :info="info" :rights="rights"></header-conponent>
    <nav-conponent class="hidden-in-xs" :header="header" :info="info" :rights="rights"></nav-conponent>
    
    <section class="section">
      <router-view></router-view>
    </section>
    <footer-conponent :footer="footer" :info="info"></footer-conponent>
  </div>
</template>

<script setup>
// import HeaderConponent from './components/Header.vue'
import HeaderConponent from './components/NavWap.vue'
import NavConponent from './components/Nav.vue'
import FooterConponent from './components/Footer.vue'
import { ref, getCurrentInstance } from 'vue'
import apis from '@/apis'

// 在组件中获取getCurrentInstance实例，使用实例中已挂载的$http方法
const { proxy } = getCurrentInstance()
const header = ref([])
const footer = ref([])
const info = ref(null)
const rights = ref([])
proxy.$http.post(apis.baseInfo).then(res => {
  if (res.code === 0) {
    header.value = res.data.headers
    footer.value = res.data.footers
    info.value = res.data.info
    rights.value = res.data.rights
  }
})


</script>

<style>
body {
  position: relative;
}
.section {
  padding-top: 75px;
  min-height: calc(100vh - 169px);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  line-height: 1.5;
  max-width: 100%;
  overflow: hidden;
}
</style>
