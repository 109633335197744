/*
 * @Author: chenjx
 * @Date: 2023-08-13 12:41:53
 * @LastEditors: chenjx
 * @LastEditTime: 2023-10-07 11:25:27
 * @Description: file content
 * @FilePath: \lingtong\src\router\index.js
 */
import * as VueRouter from 'vue-router'

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'Index',
      component: () => import('../view/index.vue'),
      meta: {
        title: 'Index',
      }
    },
    {
      path: '/index',
      name: 'Home',
      redirect: '/',
      component: () => import('../view/index.vue'),
      meta: {
        title: 'Index',
      }
    },
    {
      path: '/product',
      name: 'Product',
      redirect: '/product/video',
      component: () => import('../view/product/index.vue'),
      meta: {
        title: '产品'
      },
      children: [
        {
          path: 'more',
          name: 'More',
          component: () => import('../view/product/more.vue'),
          meta: {
            title: '百事搜'
          }
        },
        {
          path: 'video',
          name: 'Video',
          component: () => import('../view/product/video.vue'),
          meta: {
            title: '视频短信'
          }
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../view/login/index.vue'),
      meta: {
        title: '登录',
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('../view/register/index.vue'),
      meta: {
        title: '注册',
      }
    },
    {
      path: '/contactUs',
      name: 'ContactUs',
      component: () => import('../view/contactUs/index.vue'),
      meta: {
        title: '联系我们',
      }
    },
    {
      path: '/job',
      name: 'Job',
      component: () => import('../view/job/index.vue'),
      meta: {
        title: '人才招聘',
      }
    },
    {
      path: '/companyInfo',
      name: 'CompanyInfo',
      component: () => import('../view/companyInfo/index.vue'),
      redirectTo: '/companyInfo?tab=1',
      meta: {
        title: '了解我们',
      }
    },
    {
      path: '/new',
      name: 'New',
      component: () => import('../view/new/index.vue'),
      meta: {
        title: '最新动态',
      }
    },
  ],
  //跳转页面置顶
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
})

export default router
