/*
 * @Author: chenjx
 * @Date: 2023-08-13 12:18:56
 * @LastEditors: xunz hynyf@163.com
 * @LastEditTime: 2023-09-11 14:54:18
 * @Description: file content
 * @FilePath: \lingtong\src\main.js
 */
import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'animate.css'
import './assets/css/reset.less'
import './assets/css/var.less'
import '@/styles/common.less'
import 'element-plus/theme-chalk/display.css'
import axios from '@/utils/http.js'


const app = createApp(App)

/**
 * @description: 全局注入  取代 Vue.prototype 挂载
 */
app.config.globalProperties.$http = axios

app.use(router)
app.use(ElementPlus)
app.mount('#app')
