<!--
 * @Author: chenjx
 * @Date: 2023-09-18 16:30:43
 * @LastEditors: chenjx
 * @LastEditTime: 2023-09-26 11:09:00
 * @Description: file content
 * @FilePath: \lingtong\src\components\NavWap.vue
-->
<template>
  <div class="header">
    <router-link class="log" to="/">
      <img v-if="info?.logo" :src="info?.logo" onerror="javascript:this.src='/logo.png'" class="img-responsive" alt="">
    </router-link>
    <div class="menu-wrap">
      <p class="menu" @click="isShowMenu=!isShowMenu"><el-icon><Expand /></el-icon></p>
      <nav :class="['nav-wrap', isShowMenu ? 'active' : '']" @click="isShowMenu=!isShowMenu">
        <div class="nav">
          <ul class="nav-menu">
            <li class="nav-menu-item" v-for="(item, indexH) in header" :key="`${item.id}t`" @click.stop.prevent="navMouseEnter(item, indexH)">
              <p v-if="item.subs.length > 0" class="nav-menu-title">{{ item.title }} <span><arrow-down class="arrow-down" /></span> </p>
              <router-link v-else :to="item.channel_type" class="nav-menu-title">{{ item.title }}</router-link>
              <div class="nav-menu-wrap">
                <el-row class="nav-menu-sub" v-if="item.subs.length > 0" v-show="activeIndex===indexH && isShow">
                  <el-col :span="24" class="nav-menu-sub-left">
                    <p class="nav-menu-seo-title">{{ item.seo_title }} <el-icon><ArrowRight /></el-icon> </p>
                    <p v-for="sub in item.subs" :key="`${sub.id}t`">
                      <router-link class="nav-menu-sub-title" :to="sub.channel_type" @mouseenter="subActive=sub">{{ sub.title }}</router-link>
                    </p>
                  </el-col>
                </el-row>
              </div>
            </li>
          </ul>
          <div class="right-btn">
            <router-link v-for="item in rights" :key="`${item.id}t`" :class="['login', item.channel_type === '/register' ? 'register' : '']" :to="item.channel_type">{{ item.title }}</router-link>
            <router-link v-if="info?.show_reg" class="login register" to="/register">免费注册</router-link>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script setup>
import { ArrowDown, ArrowRight, Expand } from '@element-plus/icons-vue'
import { ref, defineProps, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const isShowMenu = ref(false)
const isShow = ref(false)
const activeIndex = ref(100)
const subActive = ref({
  channel_type: '/',
  seo_title: '',
  seo_desc: '',
  image: '',
})
const props = defineProps({
  header: {
    type: Array,
    default: () => ([])
  },
  rights: {
    type: Array,
    default: () => ([])
  },
  info: {
    type: Object,
    default: () => {
      return {
        logo: ''
      }
    }
  }
})

function navMouseEnter(item, index) {
  console.log(index, activeIndex.value)
  if (activeIndex.value === index) {
    isShow.value = !isShow.value
  } else {
    isShow.value = true
  }
  if (item.subs.length > 0) {
    activeIndex.value = index
    subActive.value = item.subs[0]
  }
}

const { header, rights, info} = toRefs(props)

watch(route, () => {
  isShowMenu.value = false
})

</script>
<style lang="less" scoped>

.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 75px;
  display: flex;
  background-color: #fff;
  border-bottom: solid 1px var(--el-menu-border-color);
  .log {
    margin-left: 4vw;
    margin-top: 10px;
    margin-right: 20px;
    height: 51px;
    img {
      width: 157px;
      height: 51px;
    }
  }
  .right-btn {
    padding: 20px 0;
    display: flex;
    justify-content: space-around;
    .login,
    .register {
      display: inline-block;
      line-height: 40px;
      padding: 0 20px;
      font-size: 14px;
      color: var(--subtitle-color);
      background-color: var(--section-background-color);
    }

    .register {
      color: #FFF;
      background-image: var(--active-bg-color);
    }
  }
  .menu-wrap {
    flex-grow: 2;
    .menu {
      text-align: right;
      line-height: 82px;
      padding-right: 20px;
      font-size: 30px;
    }
  }
  .nav-wrap {
    position: fixed;
    z-index: 9999;
    inset: 0;
    top: 75px;
    background: rgba(237, 239, 241, 0.8);
    transform: scaleY(0);
    transform-origin: 0 0;
    transition: all 0.2s linear;
    &.active {
      transform: scaleY(1);
    }
  }
  .nav {
    background: #EDEFF1 url(@/assets/images/1145.png) right bottom no-repeat;
    .nav-menu {
      .nav-menu-item {
        padding: 0 20px;
        .nav-menu-title {
          padding: 10px 0;
          font-size: 14px;
          color: var(--title-color);
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #DEDEDE;
          span {
            display: inline-block;
            width: 16px;
            .arrow-down {
              position: relative;
              top: 2px;
              transform: rotate(-90deg);
            }
          }
        }
        .nav-menu-wrap {
          padding-left: 20px;
          p {
            font-size: 14px;
            padding: 5px 0;
          }
          .nav-menu-sub-title {
            font-size: 12px;
            padding-left: 20px;
            color: var(--title-color);
          }
        }
      }
    }
  }
}
</style>