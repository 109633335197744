/*
 * @Author: chenjx
 * @Date: 2023-09-04 17:55:39
 * @LastEditors: chenjx
 * @LastEditTime: 2023-09-14 17:08:51
 * @Description: file content
 * @FilePath: \lingtong\src\utils\http.js
 */
import axios from 'axios'
axios.interceptors.response.use(function(response) {
  return response.data
})

export default axios