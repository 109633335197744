/*
 * @Author: xunz hynyf@163.com
 * @Date: 2023-09-05 11:17:01
 * @LastEditors: chenjx
 * @LastEditTime: 2023-09-19 18:54:42
 * @FilePath: \lingtong\src\apis.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const apis = {
  baseInfo: '/api/lt/index/baseinfo', //footers、headers、基本信息
  about: '/api/lt/index/about', //了解我们
  contact: '/api/lt/index/contact', //联系我们
  sendcode: '/api/lt/index/sendcode', //发送验证码
  product: '/api/lt/index/product', //产品
  newslist: '/api/lt/index/newslist', //集团活动、资讯
  doclist: '/api/lt/index/doclist', //
  joblist: '/api/lt/index/joblist', // 人才招聘
  resume: '/api/lt/index/resume', // 人才招聘
  words: '/api/lt/index/words', //
  newsdetail: '/api/lt/index/newsdetail',
  login: '/api/lt/index/login', // 登录
  reg: '/api/lt/index/reg', // 注册
  docdetail: '/api/lt/index/docdetail',
  index: '/api/lt/index/index' // 首页
}

for (let i in apis) {
  apis[i] = `/apis${apis[i]}`
}


export default apis