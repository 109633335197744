<!--
 * @Author: chenjx
 * @Date: 2023-09-18 16:30:43
 * @LastEditors: chenjx
 * @LastEditTime: 2023-09-26 11:09:18
 * @Description: file content
 * @FilePath: \lingtong\src\components\nav.vue
-->
<template>
  <div class="header">
    <router-link class="log" to="/">
      <img v-if="info?.logo" :src="info?.logo" onerror="() => require('@/assets/images/logo.png')" class="img-responsive" alt="">
    </router-link>
    <nav class="nav">
      <ul class="nav-menu">
        <li class="nav-menu-item" v-for="(item, indexH) in header" :key="`${item.id}t`" @mouseenter="navMouseEnter(item, indexH)" @mouseleave="activeIndex=100">
          <router-link :to="item.channel_type" class="nav-menu-title">{{ item.title }} <span><arrow-down class="arrow-down" v-if="item.subs.length > 0" /></span></router-link>
          <div class="nav-menu-wrap">
            <el-row class="nav-menu-sub" v-if="item.subs.length > 0" v-show="activeIndex===indexH">
              <el-col :sm="5" :xs="24" class="nav-menu-sub-left">
                <p class="nav-menu-seo-title">{{ item.seo_title }} <el-icon><ArrowRight /></el-icon> </p>
                <p v-for="sub in item.subs" :key="`${sub.id}t`">
                  <router-link class="nav-menu-sub-title" :to="sub.channel_type" @mouseenter="subActive=sub">{{ sub.title }}</router-link>
                </p>
              </el-col>
              <el-col :span="19" class="hidden-in-xs">
                <div class="nav-menu-sub-right">
                  <div class="nav-menu-sub-content">
                    <p class="nav-menu-sub-seo" v-html="subActive?.seo_title"></p>
                    <p class="nav-menu-sub-text" v-html="subActive?.seo_desc"></p>
                    <!-- <p class="nav-menu-sub-seo">关于我们</p> -->
                    <!-- <p class="nav-menu-sub-text">工信部等部门核准， <br/>\r\n在全国范围内从事<br/>\r\n移动信息服务、互联网信息服务、<br/>\r\n呼叫中心业务、在线数据处理与交易处理业务的通信产业公司<br/>\r\n业务覆盖范围由泉州走向全国</p> -->
                    <!-- <div class="link-wrap">
                      <router-link class="link" :to="subActive.channel_type">查看详情</router-link>
                    </div> -->
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </li>
      </ul>
    </nav>
    <div class="right-btn hidden-in-xs">
      <router-link v-for="item in rights" :key="`${item.id}t`" :class="['login', item.channel_type === '/register' ? 'register' : '']" :to="item.channel_type">{{ item.title }}</router-link>
      <router-link v-if="info?.show_reg" class="login register" to="/register">注册</router-link>
    </div>
  </div>
</template>
<script setup>
import { ArrowDown, ArrowRight } from '@element-plus/icons-vue'
import { ref, defineProps, toRefs } from 'vue'

const activeIndex = ref(100)
const subActive = ref({
  channel_type: '/',
  seo_title: '',
  seo_desc: '',
  image: '',
})
const props = defineProps({
  header: {
    type: Array,
    default: () => ([])
  },
  rights: {
    type: Array,
    default: () => ([])
  },
  info: {
    type: Object,
    default: () => {
      return {
        logo: ''
      }
    }
  }
})

function navMouseEnter(item, index) {
  if (item.subs.length > 0) {
    activeIndex.value = index
    subActive.value = item.subs[0]
  }
}

const { header, rights, info} = toRefs(props)

</script>
<style lang="less" scoped>

.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 75px;
  display: flex;
  background-color: #fff;
  border-bottom: solid 1px var(--el-menu-border-color);
  .log {
    margin-left: 4vw;
    margin-top: 10px;
    margin-right: 20px;
    height: 51px;
    img {
      width: 157px;
      height: 51px;
    }
  }
  .right-btn {
    flex-grow: 0;
    .login,
    .register {
      display: inline-block;
      line-height: 74px;
      padding: 0 20px;
      font-size: 14px;
      color: var(--subtitle-color);
      background-color: var(--section-background-color);
    }

    .register {
      color: #FFF;
      background-image: var(--active-bg-color);
    }
  }
  .nav {
    flex-grow: 1;
    flex-shrink: 0;
    width: calc(100% - 242px - 157px - 6vw);
    .nav-menu {
      display: flex;
      flex-wrap: wrap;
      .nav-menu-item {
        padding: 0 20px;
        .nav-menu-title {
          line-height: 75px;
          font-size: 14px;
          color: var(--title-color);
          span {
            display: inline-block;
            width: 16px;
            .arrow-down {
              position: relative;
              top: 2px;
            }
          }
        }
        .nav-menu-wrap {
          position: fixed;
          z-index: 9;
          top: 75px;
          left: 0;
          width: 100%;
          background: white;
          box-shadow: 0px 24px 24px 0px rgba(0,0,0,0.2);
          .nav-menu-sub {
            .nav-menu-sub-left {
              background: #EDEFF1 url(@/assets/images/1145.png) right bottom no-repeat;
              .nav-menu-seo-title {
                margin-top: 20px;
                font-size: 16px;
                color: var(--active-weight-color);
                /deep/ .el-icon {
                  top: 2px;
                }
              }
              p {
                padding: 10px 0 10px 35%;
                a {
                  color: var(--title-color);
                  font-size: 14px;
                  &:hover {
                    color: var(--active-color);
                    border-bottom: 1px solid var(--active-color);
                  }
                }
              }
            }
            .nav-menu-sub-right {
              padding: 3vw;
              background: #FFF;
              .nav-menu-sub-content {
                background: #EDEFF1 url(@/assets/images/1144.png) right bottom no-repeat;
                padding: 3vw;
                padding-bottom: 1vw;
                border-radius: 10px;
                .nav-menu-sub-seo {
                  font-size: 24px;
                  color: var(--active-weight-color);
                  margin-bottom: 20px;
                  &::after {
                    content: '';
                    display: block;
                    width: 200px;
                    height: 1px;
                    background: white;
                  }
                }
                .link-wrap {
                  text-align: right;
                  margin-top: 20px;
                  .link {
                    font-size: 14px;
                    color: var(--active-color);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>