<!--
 * @Author: chenjx
 * @Date: 2023-08-13 13:44:38
 * @LastEditors: chenjx
 * @LastEditTime: 2023-09-26 10:01:45
 * @Description: 底部
 * @FilePath: \lingtong\src\components\Footer.vue
-->
<template>
  <el-footer class="footer-wrap">
    <div class="container">
      <el-row class="footer-box flex-center">
        <el-col :md="8" :sm="{ span: 10 }" :xs="{ span: 19 }">
          <div class="text-box flex-center">
            <img src="/foot-phone.png" alt="">
            <span class="phone-text">{{ info.tel }}</span>
          </div>
          <p class="normal-text">地址： {{ info.address }}</p>
          <p class="normal-text">公交：{{ info.traffic }}</p>
        </el-col>
        <el-col :md="{ span: 12 }" :sm="{ span: 11 }" class="hidden-in-xs">
          <el-row>
            <el-col :sm="{ span: 6 }" v-for="(item, index) in footList" :key="`${index}f`" class="flex-column">
              <router-link :to="item.channel_type" class="title">{{ item.title }}</router-link>
              <router-link :to="sub.channel_type" class="normal-text" v-for="(sub, i) in item.subs" :key="`${i}s`">{{ sub.title }}</router-link>
            </el-col>
          </el-row>
        </el-col>
        <el-col :sm="{ span: 3 }" :xs="4">
          <img :src="info.qrcode_img" alt="">
        </el-col>
      </el-row>
      <span class="footer-text">{{ info.title }}<span class="footer-text" v-html="info.reg_no"></span></span>
    </div>
  </el-footer>
</template>

<script setup>
/* eslint-disable */
import { computed } from 'vue'

const props = defineProps({
  info: {
    type: Object,
    default: () => {
      return {
        title: '',
        reg_no: '',
        tel: ''
      }
    }
  },
  footer: {
    type: Array,
    default: []
  }
})
const info = computed(() => props.info)
const footList = computed(() => props.footer)



</script>
<style lang="less" scoped>
.footer-wrap {
  background-image: url(../assets/images/foot-backimg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  z-index: 1000;

  .footer-box {
    padding: 20px 0 20px 0;
    border-bottom: 1px solid #4588ff;

    .text-box {
      img {
        width: 35px;
        height: 28px;
      }
      .phone-text {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 60px;
        margin-left: 10px;
      }
    }

    .normal-text {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #D5DBF4;
      line-height: 30px;
    }

    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 48px;
    }
  }

  .footer-text {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #D5DBF4;
    line-height: 40px;
    margin-left: 48px;
   /deep/ a {
      color: #D5DBF4;
    }
  }

  .flex-center {
    display: flex;
    align-items: center;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }
}

@import '~@/styles/utils.less';

.on-xs({

  .footer-wrap {
    background-image: url(../assets/images/foot-backimg-m.png);
    width: 100%;
    height: auto;
    z-index: 1000;

    .footer-box {
      padding: 30px 0 0 0;
      border-bottom: 1px solid #4588ff;

      .text-box {
        .phone-text {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 30px;
          margin-left: 10px;
        }
      }

      .normal-text {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #D5DBF4;
        line-height: 30px;
      }

      .title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 48px;
      }
    }

    .footer-text {
      font-size: 8px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #D5DBF4;
      line-height: 24px;
      text-align: center;
      margin: 10px auto;
      white-space: pre-line;
    }
  }

  .flex-center {
    display: flex;
    align-items: center;
  }
});
</style>